import { gql, useMutation } from "@apollo/client";
import React, { useState } from "react";
import { Button, Form, Modal } from "semantic-ui-react";
import Swal from "sweetalert2";
import InputsFamily from "./InputsFamily";

const UpdateFamilyMenmber = ({ user, triggerFluid = true }) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [firstName, setFirstName] = useState(user?.firstName || "");
  const [secondName, setSecondName] = useState(user?.secondName || "");
  const [surname, setSurname] = useState(user?.surname || "");
  const [secondSurname, setSecondSurname] = useState(user?.secondSurname || "");
  /*const [residesInVillaCaracas, setResidesInVillaCaracas] = useState(
    user?.residesInVillaCaracas || false
  );*/
  const [identification, setIdentification] = useState(
    user?.identification || ""
  );
  const [birthDate, setBirthDate] = useState(user?.birthDate || "");
  const [exitDate, setExitDate] = useState(user?.exitDate || "");

  const [phone, setPhone] = useState(user?.phone || "");
  const [mail, setMail] = useState(user?.mail || "");
  const [typeIdentification, setTypeIdentification] = useState(
    user?.typeIdentification?.id || ""
  );
  const [gender, setGender] = useState(user?.gender?.id || "");
  const [maritalStatus, setMaritalStatus] = useState(
    user?.maritalStatus?.id || ""
  );
  const [expeditionDocumentDate, setExpeditionDocumentDate] = useState(
    user?.expeditionDocumentDate || ""
  );
  const [expirationDocumentDate, setExpirationDocumentDate] = useState(
    user?.expirationDocumentDate || ""
  );
  const onCompleted = async () => {
    setLoading(false);
    Swal.fire({
      icon: "success",
      title: "Completado",
      text: "Se ha actualizado el usuario de manera exitosa.",
    }).then(() => setOpen(false));
  };
  const onError = ({ graphQLErrors }) => {
    setLoading(false);
    if (graphQLErrors?.length > 0) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: graphQLErrors[0]?.message,
      });
    } else {
      Swal.fire("Lo sentimos, ha ocurrido un error.");
    }
  };
  const [mutation] = useMutation(UPDATE_FAMILY_MENMBER, {
    onCompleted,
    onError,
  });
  const handleMutation = () => {
    if (new Date(birthDate) > new Date()) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "La fecha de nacimiento es inválida.",
      });
    } else if (new Date(expeditionDocumentDate) > new Date()) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "La fecha de expedición del documento es inválida.",
      });
    } else {
      setLoading(true);
      const input = {
        id: user?.id,
        idTypeIdentification: typeIdentification,
        firstName,
        secondName,
        surname,
        secondSurname,
        birthDate,
        idGender: gender,
        idMaritalStatus: maritalStatus,
      };
      if (identification !== user?.identification)
        input.identification = identification;
      if (phone.trim()) input.phone = phone;
      if (mail.trim()) input.mail = mail;
      if (exitDate.trim()) input.exitDate = exitDate;
      if (expirationDocumentDate.trim())
        input.expirationDocumentDate = expirationDocumentDate;
      if (expeditionDocumentDate.trim())
        input.expeditionDocumentDate = expeditionDocumentDate;

      mutation({ variables: { input } });
    }
  };

  const disabled = () => {
    if (!firstName.trim()) return true;
    if (!surname.trim()) return true;
    if (!identification.trim()) return true;
    if (!birthDate.trim()) return true;
    if (!gender.trim()) return true;
    if (!maritalStatus.trim()) return true;
    if (!typeIdentification.trim()) return true;
    if (loading) return true;
  };
  return (
    <Modal
      trigger={
        triggerFluid ? (
          <Button
            onClick={() => setOpen(true)}
            content="Editar"
            size="tiny"
            fluid
          />
        ) : (
          <Button onClick={() => setOpen(true)} icon="edit" size="tiny" />
        )
      }
      onClose={() => setOpen(false)}
      open={open}
    >
      <Modal.Header>Actualizar miembro familiar</Modal.Header>
      <Modal.Content>
        <Form>
          <Form.Group widths="equal">
            <Form.Input
              required
              value={firstName}
              onChange={(e, { value }) => setFirstName(value)}
              label="Primer nombre"
            />
            <Form.Input
              value={secondName}
              onChange={(e, { value }) => setSecondName(value)}
              label="Segundo nombre"
            />
            <Form.Input
              required
              value={surname}
              onChange={(e, { value }) => setSurname(value)}
              label="Primer apellido"
            />
            <Form.Input
              value={secondSurname}
              onChange={(e, { value }) => setSecondSurname(value)}
              label="Segundo apellido"
            />
          </Form.Group>
          <Form.Input
            required
            value={identification}
            onChange={(e, { value }) => setIdentification(value)}
            label="Identificación"
          />
          <InputsFamily
            maritalStatus={maritalStatus}
            setMaritalStatus={setMaritalStatus}
            gender={gender}
            setGender={setGender}
            typeIdentification={typeIdentification}
            setTypeIdentification={setTypeIdentification}
          />

          {/*<Form.Checkbox
            checked={residesInVillaCaracas}
            onChange={(e, { checked }) => setResidesInVillaCaracas(checked)}
            label="¿Reside en Villa Caracas?"
          />
          {!residesInVillaCaracas && (
            <Message>
              Nota: Si esta opción no es marcada el usuario se actualizará
              quedando desactivado, no podrá responder encuestas, ofertas y plan
              familiar.
            </Message>
          )}
          */}
          <Form.Group widths="equal">
            <Form.Input
              value={mail}
              onChange={(e, { value }) => setMail(value)}
              label="Correo (opcional)"
            />
            <Form.Input
              value={phone}
              onChange={(e, { value }) => setPhone(value)}
              label="Teléfono (opcional)"
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Input
              required
              type="date"
              value={birthDate}
              onChange={(e, { value }) => setBirthDate(value)}
              label="Fecha de nacimiento"
            />
            <Form.Input
              type="date"
              value={expeditionDocumentDate}
              onChange={(e, { value }) => setExpeditionDocumentDate(value)}
              label="Fecha de expedición del documento (Opcional)"
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Input
              type="date"
              value={expirationDocumentDate}
              onChange={(e, { value }) => setExpirationDocumentDate(value)}
              label="Fecha de vencimiento (Opcional)"
            />
            <Form.Input
              type="date"
              value={exitDate}
              onChange={(e, { value }) => setExitDate(value)}
              label="Fecha de salida (Opcional)"
            />
          </Form.Group>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button
          disabled={disabled()}
          onClick={handleMutation}
          content="Guardar"
        />
        <Button onClick={() => setOpen(false)} content="Cerrar" />
      </Modal.Actions>
    </Modal>
  );
};

const UPDATE_FAMILY_MENMBER = gql`
  mutation ($input: UpdateFamilyMenmberInput!) {
    updateFamilyMenmber(input: $input) {
      id
      identification
      residesInVillaCaracas
      exitDate
      familyLeader {
        id
        name
        documents {
          id
          active
          identification
          typeIdentification {
            id
            name
          }
        }
      }
      mail
      phone
      birthDate
      gender {
        id
        name
      }
      maritalStatus {
        id
        name
      }
      typeIdentification {
        id
        name
      }
      expeditionDocumentDate
      expirationDocumentDate
    }
  }
`;

export default UpdateFamilyMenmber;
